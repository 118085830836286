exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-alliances-js": () => import("./../../../src/pages/alliances.js" /* webpackChunkName: "component---src-pages-alliances-js" */),
  "component---src-pages-careers-career-site-js": () => import("./../../../src/pages/careers/career-site.js" /* webpackChunkName: "component---src-pages-careers-career-site-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-old-js": () => import("./../../../src/pages/careers-old.js" /* webpackChunkName: "component---src-pages-careers-old-js" */),
  "component---src-pages-careers-why-swan-js": () => import("./../../../src/pages/careers/why-swan.js" /* webpackChunkName: "component---src-pages-careers-why-swan-js" */),
  "component---src-pages-case-study-thank-you-js": () => import("./../../../src/pages/case-study-thank-you.js" /* webpackChunkName: "component---src-pages-case-study-thank-you-js" */),
  "component---src-pages-consulting-agility-js": () => import("./../../../src/pages/consulting/agility.js" /* webpackChunkName: "component---src-pages-consulting-agility-js" */),
  "component---src-pages-consulting-economy-js": () => import("./../../../src/pages/consulting/economy.js" /* webpackChunkName: "component---src-pages-consulting-economy-js" */),
  "component---src-pages-consulting-experience-js": () => import("./../../../src/pages/consulting/experience.js" /* webpackChunkName: "component---src-pages-consulting-experience-js" */),
  "component---src-pages-consulting-governance-js": () => import("./../../../src/pages/consulting/governance.js" /* webpackChunkName: "component---src-pages-consulting-governance-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-csp-about-us-js": () => import("./../../../src/pages/csp/about-us.js" /* webpackChunkName: "component---src-pages-csp-about-us-js" */),
  "component---src-pages-csp-index-js": () => import("./../../../src/pages/csp/index.js" /* webpackChunkName: "component---src-pages-csp-index-js" */),
  "component---src-pages-csr-js": () => import("./../../../src/pages/csr.js" /* webpackChunkName: "component---src-pages-csr-js" */),
  "component---src-pages-enquiry-thank-you-js": () => import("./../../../src/pages/enquiry-thank-you.js" /* webpackChunkName: "component---src-pages-enquiry-thank-you-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-projects-js": () => import("./../../../src/pages/it-projects.js" /* webpackChunkName: "component---src-pages-it-projects-js" */),
  "component---src-pages-managed-services-js": () => import("./../../../src/pages/managed-services.js" /* webpackChunkName: "component---src-pages-managed-services-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news-events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-case-studies-js": () => import("./../../../src/pages/resources/case-studies.js" /* webpackChunkName: "component---src-pages-resources-case-studies-js" */),
  "component---src-pages-resources-insights-js": () => import("./../../../src/pages/resources/insights.js" /* webpackChunkName: "component---src-pages-resources-insights-js" */),
  "component---src-pages-resources-white-papers-js": () => import("./../../../src/pages/resources/white-papers.js" /* webpackChunkName: "component---src-pages-resources-white-papers-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-subscribtion-thank-you-js": () => import("./../../../src/pages/subscribtion-thank-you.js" /* webpackChunkName: "component---src-pages-subscribtion-thank-you-js" */),
  "component---src-pages-subscription-thank-you-js": () => import("./../../../src/pages/subscription-thank-you.js" /* webpackChunkName: "component---src-pages-subscription-thank-you-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/templates/BlogDetailPage.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-case-study-detail-page-js": () => import("./../../../src/templates/CaseStudyDetailPage.js" /* webpackChunkName: "component---src-templates-case-study-detail-page-js" */),
  "component---src-templates-it-projects-child-js": () => import("./../../../src/templates/ItProjectsChild.js" /* webpackChunkName: "component---src-templates-it-projects-child-js" */),
  "component---src-templates-managed-services-child-js": () => import("./../../../src/templates/ManagedServicesChild.js" /* webpackChunkName: "component---src-templates-managed-services-child-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/Footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-navbar-js": () => import("./../../../src/components/Navbar.js" /* webpackChunkName: "slice---src-components-navbar-js" */)
}

